import { StorageItem } from "./StorageItem";

class ApiClient {
    getUrl(controller) {
        const getUrl = window.location;
        return getUrl.protocol + "//" + getUrl.host + "/" + "api/barcaller/" + controller;
    }

    getHeaders() {
        const token = localStorage[StorageItem.Token]; // window.sessionStorage.getItem('token');
        const headers = {
            'Content-Type': 'application/json',
        }

        if (token != null)
            headers['Authorization'] = token;

        return headers;
}

    log(url, body) {
        console.log(`Call API: ${this.getUrl(url)}, body: ${JSON.stringify(body)}`)
}

    async readData(response) {
        const mediaType = response.headers.get('content-type');
        return mediaType?.includes('json') ? await response.json() : await response.text();
    }

    async get(url) {
        const init = {
            method: 'GET',
            headers: this.getHeaders(),
        };
        const response = await fetch(this.getUrl(url), init);
        const data = await this.readData(response);
        return { success: response.ok, response: data }
    }

    async post(url, body) {
        
        const init = {
            method: 'POST',
            headers: this.getHeaders(),
            body: JSON.stringify(body)
        };

        const response = await fetch(this.getUrl(url), init);
        const data = await this.readData(response);
        return { success: response.ok, response: data }
    }

    async put(url, body) {
        const init = {
            method: 'PUT',
            headers: this.getHeaders(),
            body: JSON.stringify(body)
        };

        const response = await fetch(this.getUrl(url), init);

        const data = await this.readData(response);
        return { success: response.ok, response: data }
    }
}

export const api = new ApiClient();