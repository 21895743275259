export const images = [
    // caller
    '../images/background.png',
    '../images/bingo.png',
    '../images/play-logo.png',
    '../images/bingonomics-logo.png',
    '../images/balls.json',
    '../images/letterOnBalls.json',
    '../images/call-button.json',
    '../images/free.png',

    // button container
    '../images/verify-cards.json',
    '../images/cancel-call.json',
    '../images/menu.json',
    '../images/start-game.json',
    '../images/next-game.json',

    // verify container
    '../images/auto-buttons.json',
    '../images/letters.json',
    '../images/daub.json',
    '../images/close.json',
    '../images/accept.json',
    '../images/accept-all.json',
    '../images/reject.json',
    '../images/pad_0.json',
    '../images/pad_1.json',
    '../images/pad_2.json',
    '../images/pad_3.json',
    '../images/pad_4.json',
    '../images/pad_5.json',
    '../images/pad_6.json',
    '../images/pad_7.json',
    '../images/pad_8.json',
    '../images/pad_9.json',
    '../images/pad_back.json',
    '../images/pad_delete.json',
    '../images/pad_submit.json',

    // menu
    '../images/function-menu.png',
    '../images/logout.json',
    '../images/callers-choice.json',
    '../images/winners-form.json',
    '../images/hotball.json',
    '../images/jump-game.json',
    '../images/wild-any.json',
    '../images/wild-even.json',
    '../images/wild-odd.json',
    '../images/cancel-menu.json',

    // menu - any
    '../images/bonusnums.json',
    '../images/bonus-cancel.json',
    '../images/bonus-bg.png',

    // menu - callers choice
    '../images/choice-bg.png',
    '../images/cc-choose.json',
    '../images/cc-cancel.json',

    // menu - jump game
    '../images/jump-game-bg.png',

    // popup modal
    '../images/popup-bg.png',
    '../images/popup-yes.json',
    '../images/popup-no.json',
];