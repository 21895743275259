import { api } from "./apiClient";
import { images } from "./assets";
import { StorageItem } from "./StorageItem";

class Login {
    private propertyId = -1;

    init = async () => {
        // setup events on in index.html
        document.getElementById('loginContainer')!.addEventListener('submit', async (e) => await this.onLoginClick(e));
        document.getElementById('resumeBtn')!.addEventListener('click', async () => await this.startNewDay(false));
        document.getElementById('startNewBtn')!.addEventListener('click', async () => await this.startNewDay(true));

        const keepLoggedIn = document.getElementById("keepLoggedIn") as HTMLInputElement;
        const loginInput = document.getElementById("user_login") as HTMLInputElement;

        if (localStorage[StorageItem.StayLoggedIn] && localStorage[StorageItem.Username]) {
            keepLoggedIn.setAttribute("checked", "checked");
            loginInput.value = localStorage[StorageItem.Username];
        } else {
            keepLoggedIn.removeAttribute("checked");
            loginInput.value = "";
        }

        document.getElementById('property')!.addEventListener('change', async (i) => {
            const target = i.target as HTMLTextAreaElement;
            const propertyId = parseInt(target.value);
            await this.selectProperty(propertyId);
        });

        if (localStorage[StorageItem.StayLoggedIn] && localStorage[StorageItem.Token]) {
            this.showLoggingIn();
            await this.getProperties();
        }
    }

    private showLoggingIn() {
        document.getElementById('loginButton')!.style.display = 'none';
        document.getElementById('lds-ellipsis')!.style.display = 'block';
    }

    private resetLoggingIn() {
        document.getElementById('loginButton')!.style.display = 'block';
        document.getElementById('lds-ellipsis')!.style.display = 'none';
    }

    private async authenticate() {
        const username = (document.getElementById('user_login') as HTMLInputElement).value;
        const password = (document.getElementById('user_password') as HTMLInputElement).value;

        if (!username) {
            document.getElementById('loginErrorMessage')!.innerHTML = "User name is required";
            return;
        }

        if (!password) {
            document.getElementById('loginErrorMessage')!.innerHTML = "Password is required";
            return;
        }

        this.showLoggingIn();

        const keepLoggedIn = document.getElementById("keepLoggedIn") as HTMLInputElement;
        if (keepLoggedIn.checked && username) {
            localStorage[StorageItem.Username] = username;
            localStorage[StorageItem.StayLoggedIn] = username;
        } else {
            localStorage.removeItem(StorageItem.Username);
            localStorage.removeItem(StorageItem.StayLoggedIn);
        }

        let result = await api.post("authenticate", { UserName: username, Password: password });
        if (!result.success) {
            document.getElementById('loginErrorMessage')!.innerHTML = result.response;
            this.resetLoggingIn();
            return;
        }

        localStorage[StorageItem.Token] = result.response;
    }

    private async getProperties() {
        const properties = (await api.get("properties")).response as any[];

        if (properties !== null && properties.length > 0) {
            document.getElementById('loginContainer')!.style.display = 'none';

            // good login
            if (properties.length === 1) {
                this.selectProperty(properties[0].propertyId);
            }
            else {
                // need user to select their property
                document.getElementById('propertyDiv')!.style.display = 'block';

                var propertySelect = document.getElementById('property') as HTMLSelectElement;

                propertySelect.options[0] = new Option("Select Property");
                propertySelect.options[0].disabled = true;

                const username = (document.getElementById('user_login') as HTMLInputElement).value;
                if (username === 'jeremy') {
                    propertySelect.options.add(new Option("Jeremy", `${350}`));
                } else {
                    properties.filter(x => !x.isDisabled).sort((a, b) => a.propertyName.localeCompare(b.propertyName))
                        .forEach((p) => propertySelect.options.add(new Option(p.propertyName, p.propertyId)));
                }
            }
        }
        else {
            // invalid login
            this.resetLoggingIn();
            document.getElementById('loginErrorMessage')!.innerHTML = "Invalid Login";
        }
    }

    onLoginClick = async (e: any) => {
        e.preventDefault();
        await this.authenticate();
        await this.getProperties();
    };

    selectProperty = async (propertyId) => {
        document.getElementById("propertyDiv")!.style.display = "none";
        this.propertyId = propertyId;
        window.sessionStorage.setItem("propertyId", "" + propertyId);
        const result = await api.get("isNewDay/" + propertyId);

        if (!result.success) {
            document.getElementById('loginErrorMessage')!.innerHTML = result.response;
            this.resetLoggingIn();
            return;
        }

        if (result.response) {
            this.startGame();
        } else {
            // See if user wants to start another day
            document.getElementById("yesnoMsg")!.style.display = "flex";
        }
    };

    startNewDay = async (startDay) => {
        document.getElementById("yesnoMsg")!.style.display = "none";
        if (startDay) {
            const result = await api.post(`closeDay`, { propertyId: this.propertyId });
            if (!result.success) {
                //document.getElementById('loginErrorMessage').innerHTML = result.response;
                return;
            }
            else {
                this.startGame();
            }
        }
        else {
            this.startGame();
        }
    };

    startGame = () => {
        window.location.href = "caller.html";
    };
}

const login = new Login();

if (!window.location.href.includes("caller.html")) {
    login.init();
}

// preload caller assets/images
images.forEach((path) => {
    if (path.endsWith(".json")) {
        fetch(path)
            .then(res => res.json())
            .then(data => new Image().src = `../images/${data.meta.image}`)
            .catch(err => { throw err });
    } else {
        new Image().src = path;
    }
});